import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In a private town in Milan lived a family of five in a three-story house. They were an active Italian family who enjoyed sports and adventures. Laura was a mother of three children, Rosa who was the oldest, Olivia who was seventeen, and fifteen-year-old Stefano. Laura was a strong woman with a great history in her football career and her husband Luca was a former boxing champion.`}</p>
    <p>{`On an early summer morning, Laura woke up from the singing of the birds flying outside the window. It was six am and everybody in the house was still asleep. Laying in bed, the bright warm sun shining in her face, a great idea popped into her mind. She decided to go and sort their belongings in the attic in order to see if there is something that she could give away. Quietly she went out of the room and headed towards the stairs.`}</p>
    <p>{`Opening the door to the attic her eyes started running around from box to box. She didn’t know where to start. The first box she got onto is the old clothes and toys of her children. But when she moved that box out of the way, she burst into tears of joy. A big box of her football belongings was standing right in front of her. Tears pouring down her face and the memories filling up her head.`}</p>
    <p>{`From a young age, Laura showed her passion for football. She dreamed of being in the professional league but the society around her didn’t believe in her abilities and discriminated against her because she is a girl. “Girls don’t play football,” “Your place is in the kitchen,” You have no chances,” people would pass Laura playing football and scream the offensive words at her. But Laura was a smart girl. She ignored all of the biased mindsets about gender discrimination and continued working towards her dream.`}</p>
    <p>{`Laura got out her very first ripped, dirty football ball from the box and felt as if she returned to that time. From the age of eight to twelve, she was all on her own. She didn’t know any girls who played football and the boys wouldn’t want to let her play with them because they didn’t want girls to be in their teams. So for four years, it was just her, the ball, and the grass field. The field was plain grass, without goals or outlines. Laura used her creative mind and made goals with her shoes. She imagined that she had opponents and trained her dribbling and shooting skills. Each day she would come out to that field and play. Play for her own enjoyment. This was the way she relaxed.`}</p>
    <p>{`Looking at her first cleats Laura flies back to the day when her mom took her outside and brought her to a football field where a girls team trained. It was the only girl’s teenager football team found in Bari. It was the first time in her life she saw girls who had good football skills. She felt that it was her team. The team that can achieve high levels. When she joined the team she was given a uniform and cleats. Those were her first football cleats. Before she mostly used to play barefoot. This was the day that opened a door of opportunities to Laura.`}</p>
    <p>{`Getting out the shirt with the signatures of her teammates she brings it close to her nose and feels that smell. The smell of teamwork, cooperation, and success. Holding this shirt in her hands she spun a whole movie of her team’s history in her head. Their training, achievements, downfalls, arguments, and victories.`}</p>
    <p>{`Beneath that shirt laid Laura’s biggest awards. It was the medal from her team’s victory at the Women’s Football World Championship and the Golden Ball she was awarded for being the best player in the world among both men and women. Picking up the trophy from the box she spotted that it was sticky and had a sweet smell until when she remembered how her team and coach sprayed her with champagne after the ceremony.`}</p>
    <p>{`All the flashbacks made it seem as if it was yesterday, but in reality, more than twenty years have passed since the last time she stood in the stadium with her team. Sitting on the floor in the attic surrounded by all those wonderful memories, Laura felt the pride once more. Not only the victory is what made her feel proud of herself but being able to prove others wrong. Reaching her dream she showed that it is not gender that matters but the determination and effort you put into something you feel passionate about.`}</p>
    <p>{`Laura heard footsteps coming up the stairs so she quickly wiped off her tears. She doesn’t like when others, especially her children, see her cry even if those are tears of joy. She likes to be positive and full of bright energy around people.`}</p>
    <p>{`“Mom, we’ve been looking for you all around the house, what are you up to here?” nervously asked Olivia peaking from the door.`}</p>
    <p>{`Laura sighs and stares at the box.`}</p>
    <p>{`“Are you okay?” she continued as she slowly walked into the attic.`}</p>
    <p>{`“Yes, darling, I’m fine,” mysteriously responded Laura.`}</p>
    <p>{`“I just wanted to tell you that Rosa made breakfast, do you want to come eat with us?” invited Olivia.`}</p>
    <p>{`“I’ll join you in a bit, just need to spend some time thinking over the movie I have just watched,” smiled Laura.`}</p>
    <p>{`The door closes and Laura plays the movie once more.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      